<template>
  <div>
    <div class="flex justify-between py-2">
      <a-dropdown-button @click="open_add">
        {{ $t('add') }}
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="handle_setting_up_the_speakers">
            <a-icon type="setting"/>
            {{ $t('setting_up_the_speakers') }}
          </a-menu-item>
          <a-menu-item key="1" @click="download_exel">
            <a-icon type="download" />
            {{ $t('download_exel') }}
          </a-menu-item>
        </a-menu>
      </a-dropdown-button>
    </div>
<!--    <a-collapse accordion class="mb-2">-->
<!--      <a-collapse-panel key="1" :header="$t('filters')">-->
<!--        <FormSelect :formItems="filters" ref="refFormCreateRequest" :cols="1" class="mb-2"/>-->
<!--      </a-collapse-panel>-->
<!--    </a-collapse>-->
<!--    <card>-->
      <custom-tabular ref="refCustomTabular" :column_defs="column_defs" :end_point="'organizations/profiles_client/'" @open_drawer="open_drawer"/>
<!--    </card>-->
    <setting-up-the-speakers ref="refSettingUpTheSpeakers" :column_defs="column_defs" :pages_front="con_fig" @updated_table="updated_table"/>
  </div>
</template>

<script>
import Card from "@/crm_components/card/Card";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import CustomTabular from "@/components/CustomTabular/CustomTabular.vue";
import SettingUpTheSpeakers from "@/components/SettingIpTheSpeakers/SettingUpTheSpeakers.vue";
import OpenDrawerRequestMixin from "@/pages/AddClient/RequestMixins/OpenDrawerRequestMixin.vue";
import {mapGetters} from "vuex";
import ProfileSelectView from "@/components/AntSelect/ProfileSelectView.vue";
import {EventBus} from "@/configs/eventBus";
export default {
  name: "GlobalPage",
  components: {
    SettingUpTheSpeakers,
    CustomTabular,
    Card,
    FormSelect,
  },

  props:{

  },
  async mounted() {
    EventBus.$on('client_and_request_handle_submit', this.client_and_request_handle_submit);
  },
  beforeDestroy(){
    EventBus.$off('client_and_request_handle_submit');
  },
  computed:{
    con_fig(){
      console.log('this.GET_PAGES_FRONT', this.GET_PAGES_FRONT)
      if (this.GET_PAGES_FRONT){
        return this.GET_PAGES_FRONT.find(ele=>ele.code==='add_client')
      }
    },
    ...mapGetters({
      GET_PROFILE_COLUMN_DEFS:'user/GET_PROFILE_COLUMN_DEFS',
      GET_PAGES_FRONT:'user/GET_PAGES_FRONT',
    }),
    column_defs(){
      if (this.con_fig){
        if (this.GET_PROFILE_COLUMN_DEFS){
          let filter_column_defs = this.GET_PROFILE_COLUMN_DEFS.filter(ele=>ele.pages_front === this.con_fig.uid)
          let list = []
          this.con_fig.column_defs.forEach((ele)=>{
            let c = filter_column_defs.find(item=>item.column_defs === ele.uid)
            let formatter = undefined
            if (ele.field === 'main_number'){
              formatter = this.value_formatter_main_number
            }else if(ele.field === 'dop_number'){
              formatter = this.value_formatter_dop_number
            }
            if (c){
              list.push({
                ...ele,
                use:c.use,
                sort:c.sort,
                formatter:formatter
              })
            }else{
              list.push({
                ...ele,
                formatter:formatter
              })
            }

          })
          // let clone = this.column_defs_props.slice().sort((a, b) => a.sort - b.sort);
          return list.slice().sort((a, b) => a.sort - b.sort);
        }
      }
    }
  },
  mixins:[
    OpenDrawerRequestMixin
  ],
  data(){
    return {
      filters:[
        {
          model:'health_threat',
          type:'checkbox',
          show_label: false,
          use_router_replace:true
        },
        {
          model:'filter_date_start_end',
          type:'date_start_end',
          use_router_replace:true
        },
        {
          model: 'request_type',
          type:'select',
          end_point:'organizations/request_types/',
          use_router_replace:true
        },
        {
          model: 'client',
          type:'select',
          end_point:'organizations/profiles_client/',
          select_list_component:ProfileSelectView,
          use_router_replace:true
        },
        {
          model: 'level_request',
          type:'select',
          end_point:'organizations/levels/',
          use_router_replace:true
        },
        {
          model: 'source_request',
          type:'select',
          end_point:'organizations/sources/',
          use_router_replace:true
        },
        {
          model: 'organization_request',
          type:'select',
          end_point:'organizations/organizations/',
          use_router_replace:true
        },
        {
          model: 'responsible_manager',
          type:'select',
          end_point:'organizations/performer/',
          select_list_component:ProfileSelectView,
          use_router_replace:true
        },
        {
          model: 'operator',
          type:'select',
          end_point:'organizations/operator/',
          select_list_component:ProfileSelectView,
          use_router_replace:true
        },
        {
          model: 'region_fk',
          type:'select',
          end_point:'organizations/regions/',
          use_router_replace:true
        },
        {
          model: 'status_request',
          type:'select',
          end_point:'organizations/statuses/',
          use_router_replace:true
        },

      ]
    }
  },
  methods:{
    client_and_request_handle_submit(data, refForm){
      console.log('data sdsdfa', data.data.profile)
      console.log('data refForm', refForm)
      let clone_data = [
          data.data.profile,
          ...this.$refs.refCustomTabular.data
      ]
      console.log('clone_data', clone_data)
      this.$refs.refCustomTabular.data = clone_data
      // this.$refs.refCustomTabular.data
    },
    value_formatter_main_number(cell, formatterParams, onRendered){
      try {
        return cell.getData().phone_numbers.find(item => item.main === true).number
      }catch (e) {
        return null
      }
    },
    value_formatter_dop_number(cell, formatterParams, onRendered){
      try {
        return cell.getData().phone_numbers
            .filter(item => item.main === false)
            .map(item => item.number)
            .join(' ');
      }catch (e) {
        return null
      }
    },
    download_exel(){
      this.$refs.refCustomTabular.downloadAllData()
    },
    open_drawer(data){
      console.log('data sasdsadadssadsaddsa', data)
      try {
        EventBus.$emit('event_client_show_drawer', data.data, false);

      }catch (e) {
        console.log('adsdas assadds', e)
      }
    },
    open_add(){
        // this.$router.push({name:'create_request'})
        EventBus.$emit('open_modal_client_and_request', true);
    },
    handle_setting_up_the_speakers(){
      this.$refs.refSettingUpTheSpeakers.showModal()
    },
    updated_table(){
      this.$refs.refCustomTabular.$refs.refTabular.method_mounted()
    }
  },
}
</script>

<style lang="scss">
@import "src/scss/base/_variables.scss";
.default-button{
  width: 200px;
  border-radius: $border-radius-base;
}
.save-button{

}
</style>
